export const COMMUNITY_USER_NOTE_FULL_FRAGMENT = `
  fragment communityUserNoteFullFragment on CommunityUserNote {
    uid
    title
    content
    contentPlain
    user {
      uid
    }
    deleted
    createdTime
    updatedTime
    deletedTime
    links {
      uid
      targetType
      target {
        __typename
        ... on CommunityUser {
          uid
          firstName
          lastName
          pictureFileResource {
            schemaCode
            path
          }
        }
        ... on Exhibitor {
          uid
          name
          logoFileResource {
            schemaCode
            path
          }
        }
        ... on Product {
          uid
          name
          images {
            fullFileResource {
              schemaCode
              path
            }
          }
        }
        ... on LargeProduct {
          uid
          name
          images {
            fullFileResource {
              schemaCode
              path
            }
          }
        }
        ... on Deal {
          uid
          name
          title
          displayFileResource {
            schemaCode
            path
          }
        }
        ... on Session {
          uid
          name
          imageFileResource {
            schemaCode
            path
          }
        }
        ... on Speaker {
          uid
          firstName
          lastName
          prefix
          suffix
          photoFileResource {
            schemaCode
            path
          }
        }
        ... on Article {
          uid
          name
          images {
            schemaCode
            path
          }
        }
      }
    }
  }
`;
